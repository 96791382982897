.yes-modal-true {
  margin-left: auto;
  margin-right: auto;
  position: fixed;
  box-sizing: border-box;
  padding-top: 5%;
  top: 0;
  width: 100%;
}
.modal-container{
  max-width: 400px;
  margin-left:auto;
  margin-right: auto;
}
.yes-modal--hidden-true,
.yes-modal-response-button--icon {
  position: absolute;
  width: 100%;
}
.yes-modal-true,
.yes-modal--hidden-true {
  box-sizing: border-box;
  padding-left: 16px;
  padding-right: 16px;
  justify-items: left;
  bottom: 0;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  border-radius: 5% 5% 0px 0px;
  background-color: #41416f;
  opacity: 1;
  color: black;
}


.yes-modal-true {
  animation: scrollUp 0.25s forwards;
}
.yes-modal--hidden-false {
  display: none;
}
.yes-modal--hidden-true, .yes-modal--hidden-false {
  position: fixed;
  animation: scrollDown 0.25s forwards;
}
.yes-modal-header {
  font-size: 28px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: #ffffff;
  margin-top: 5%;
  margin-bottom: 5%;
}
.rsvp-input{
  box-sizing: border-box;
  border-radius: 25px;
  box-shadow: 0 10px 20px 0 rgba(64, 117, 205, 0.08);
  background-color: #2a3164;
  color: white;
  text-align: center;
  display: block;
  border: none;
  outline: none;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.88;
  letter-spacing: 0.23px;
  text-align: center;
  color: #ffffff;
  width: 100%;
  margin-top: 12px;
  margin-bottom: 12px;
  padding-top: 16px;
  padding-bottom: 16px;
}
.intl-tel-input{
  width: 100%;
}
.intl-tel-input.allow-dropdown input{
  padding-right: 50px !important;
}
.yes-modal-sub-header{
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #9ea3d6;
  margin-bottom: 5%;
}
.let-us-know{
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #9c9eb9;
}
.yes-modal-response-button {
  outline: none;
}
.yes-modal-response-button:hover {
  cursor: pointer;
}

.yes-modal-response-button {
  width: 100%;
  display: flex;
  background-color: var(--invites-button-bg-color);
  font-size: 1rem;
  border-radius: 1.563rem;
  border-style: none;
  font-weight: 600;
  height: 4.167rem;
}
.yes-modal-cancel {
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.88;
  letter-spacing: 0.23px;
  text-align: center;
  color: #7265e3;
}
.yes-modal-response-button--icon {
  height: 2rem;
  width: 2rem;
  align-self: center;
}
.yes-modal-response-button--text {
  margin: auto;
  font-size: 17px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #363858;
}
@media only screen and (max-height: 480px) {
  .yes-modal-header{
    font-size: 1em;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .yes-modal-sub-header{
    font-size: 0.8em;
  }
  .yes-modal-true{

  }
}
/* @media screen and (min-width: 700px) {
  .yes-modal-true,
  .yes-modal--hidden-true {
    min-width: 0;
    margin: 0;
    width: 50%;
  }
  .yes-modal--hidden-true {
    position: fixed;
    animation: scrollDownLarge 0.25s forwards;
  }
}

@media screen and (min-width: 1050px) {
  .yes-modal-true,
  .yes-modal--hidden-true {
    min-width: 0;
    margin: 0;
    width: 600px;
  }
} */

@keyframes scrollUpLarge {
  from {
    right: 200px;
    width: 100%;
  }
  to {
    right: 200px;
    width: 100%;
  }
}

@keyframes scrollUp {
  from {
    bottom: -400px;
  }
  to {
    bottom: 0px;
  }
}
@keyframes scrollDown {
  from {
    bottom: 0px;
  }
  to {
    bottom: -1000px;
  }
}
@keyframes scrollDownLarge {
  from {
    bottom: 0px;
  }
  to {
    bottom: -400px;
  }
}

/* @keyframes scrollLeft {
  from {
    right: 0px;
  }
  to {
    right: 4000px;
  }
}

@keyframes scrollLeftLarge {
  from {
    right: 50%;
  }
  to {
    right: 4000px;
  }
} */
