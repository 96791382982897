

.attending-modal--hidden-true,
.attending-modal-response-button--icon {
  position: absolute;
}
.attending-modal-true,
.attending-modal--hidden-true {
  display: grid;
  box-sizing: border-box;
  padding: 20px;
  justify-items: center;
  bottom: 0;
  height: 430px;
  border-radius: 5% 5% 0px 0px;
  background-color: white;
  color: black;
  width: 100% !important;
}


.attending-modal-true {
  animation: scrollUp 0.25s forwards;
}
.attending-modal--hidden-false {
  display: none;
}
.attending-modal--hidden-true, .attending-modal--hidden-false {
  position: fixed;
  animation: scrollDown 0.25s forwards;
}
.attending-modal-header {
  margin-top: 17px;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0.29px;
  text-align: center;
  color: #2d3142;
}
.let-us-know{
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #9c9eb9;
}
.attending-modal-response-button {
  outline: none;
}
.attending-modal-response-button:hover {
  cursor: pointer;
}

.attending-modal-response-button {
  width: 100%;
  display: flex;
  background-color: var(--invites-button-bg-color);
  font-size: 1rem;
  border-radius: 1.563rem;
  border-style: none;
  font-weight: 600;
  height: 50px;
}
.attending-modal-cancel {
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.88;
  letter-spacing: 0.23px;
  text-align: center;
  color: #7265e3;
}
.attending-modal-response-button--icon {
  height: 24px;
  width: 24px;
  margin-left: 10px;
  align-self: center;
}
.attending-modal-response-button--text {
  margin: auto;
  font-size: 17px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #363858;
}

/* @media screen and (min-width: 700px) {
  .attending-modal-true,
  .attending-modal--hidden-true {
    min-width: 0;
    margin: 0;
    width: 50%;
  }
  .attending-modal--hidden-true {
    position: fixed;
    animation: scrollLeftLarge 0.25s forwards;
  }
}

@media screen and (min-width: 1050px) {
  .attending-modal-true,
  .attending-modal--hidden-true {
    min-width: 0;
    margin: 0;
    width: 600px;
  }
} */

@keyframes scrollUpLarge {
  from {
    right: 200px;
    width: 100%;
  }
  to {
    right: 200px;
    width: 100%;
  }
}
@keyframes scrollUp {
  from {
    bottom: -400px;
  }
  to {
    bottom: 0px;
  }
}
@keyframes scrollDown {
  from {
    bottom: 0px;
  }
  to {
    bottom: -800px;
  }
}
@keyframes scrollDownLarge {
  from {
    bottom: 0px;
  }
  to {
    bottom: -400px;
  }
}


/* @keyframes scrollLeft {
  from {
    right: 0px;
  }
  to {
    right: 4000px;
  }
}

@keyframes scrollLeftLarge {
  from {
    right: 50%;
  }
  to {
    right: 4000px;
  }
} */
