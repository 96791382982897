.prompt-banner-false-true,
.prompt-banner-true-true,
.prompt-banner-true-false {
  background-color: white;
  border-radius: 5% 5% 0px 0px;
  display: grid;
  grid-template-rows: 4.167rem 4.167rem 4.167rem 10rem;
  grid-gap: 1rem;
  justify-content: center;
  align-items: end;
  text-align: center;
  color: white;
  height: 400px;
  font-family: var(--invites-main-font);
  position: fixed;
  bottom: 0;
  margin-left: -1rem;
  min-width: 100vw;
}
.prompt-banner-true-true,
.prompt-banner-true-false {
  animation: scrollInPay 0.25s forwards;
}
.prompt-banner-false-false {
  display: none;
}
.prompt-banner-false-true {
  animation: scrollOutPay 1s forwards;
}
.prompt-banner--title {
  font-weight: var(--invites-medium-font);
  color: var(--invites-response-modal-text);
}

.prompt-banner--desc {
  color: var(--invites-form-holder);
  font-weight: var(--invites-regular-font);
}
.prompt-banner-cancel {
  outline: none;
}

.prompt-banner-cancel > img {
  max-height: 7rem;
  max-width: 7rem;
}

.prompt-banner-button {
  color: var(--invites-dark-bg);
  background: var(--invites-cal-gradient);
  font-weight: var(--invites-medium-font);
  border-radius: 1.833em;
  color: white;
  font-size: 1.25em;
  min-height: 3.667em;
  border: none;
  outline: none;
}

@media screen and (min-width: 700px) {
  .prompt-banner {
    display: none;
  }
}

@keyframes scrollInApp {
  from {
    right: -400px;
  }
  to {
    right: 00px;
  }
}
@keyframes scrollOutApp {
  from {
    right: 0px;
  }
  to {
    right: 4000px;
  }
}
