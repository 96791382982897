.video-container{
  object-fit: fill;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  margin:0 auto;
  overflow:hidden;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
}

video {
  object-fit: fill;
}
.rh5v-Overlay_inner {
  top: calc(50vh - 72px) !important;
  margin-left: -36px;
  border-radius: 36px;
  width: 72px;
  height: 72px;
  background-image: linear-gradient(95deg, #8b63e6 3%, #7265e3);
  transform: translateZ(0);
}