.rsvp-user-cell {
  display: flex;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.2px;
  color: #ffffff;
  align-items: center;
  font-weight: var(--invites-regular-font);
  outline: none;
}
.rsvp-user-cell-col{
  flex: 1;
}
.rsvp-user-cell--number {
width: 24px;
}
.rsvp-user-response > img {
  height: 24px;
  width: 24px;
  float: right;
}
.rsvp-declined-list {
  display: flex;
  color: white;
  flex-wrap: wrap;
}
.rsvp-declined-list > * {
  overflow-wrap: break-word;
}
.rsvp-declined-name {
  white-space: nowrap;
}
.rsvp-declined--spanner {
  padding-left: 0.5rem;
  color: var(--invites-form-holder);
  min-height: 100%;
}
.rsvp-declined-name:last-child > .rsvp-declined--spanner {
  display: none;
}
.rsvp-declined-list > * {
  padding: 0 0.5rem 0 0rem;
}

.rsvp-user-paid--REQUESTED {
  color: red;
  font-family: var(--invites-main-font);
  font-weight: var(--invites-regular-font);
  border: 1px solid red;
  text-align: center;
}
.rsvp-user-paid--PAYMENT_SENT {
  color: orange;
  font-family: var(--invites-main-font);
  font-weight: var(--invites-regular-font);
  border: 1px solid orange;
  text-align: center;
}
.rsvp-user-paid--PAYMENT_CONFIRMED {
  color: green;
  font-family: var(--invites-main-font);
  font-weight: var(--invites-regular-font);
  border: 1px solid green;
  text-align: center;
}
