body {
  /* width: 100%;
  height: 100%; */
  overflow: hidden;
}

:root {
  --width: 100vw;
  --height: calc(var(--vh, 1vh) * 100);
  --inside-width: var(--width);
  --inside-height: calc(var(--height) - 80px);
  --video-height: calc(var(--inside-width) * 1.77)
}

.rsvp-container,
.flip-card-front,
.flip-card-back,
.video-container,
.video-element,
.front-card-inner {
  width: var(--width);
}
.rh5v-DefaultPlayer_controls{
  top: 10px !important;
  left: 10px !important;
  opacity: 1 !important;
  background-color: transparent !important;
}
.rh5v-Volume_component:hover, .rh5v-Volume_slider, .rh5v-Volume_track{
  background-color: transparent !important;
}
.rh5v-Volume_track{
  opacity: 0;
}
.rh5v-Volume_component {
  background-color: transparent;
  opacity: 1;
}
.rh5v-Volume_icon, .rh5v-Volume_button{
  height: 36px;
  width: 36px;
}
.rh5v-Volume_icon{
  fill: transparent;
}
.rsvp-container,
.video-container {
  color: white;
  left: 50%;
  position: absolute;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.respond-button {
  border-radius: 23.5px;
  text-align: center;
  background-image: linear-gradient(95deg, #8b63e6 3%, #7265e3);
  font-size: 14px;
  font-weight: 500;
  color: #ffffff;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-decoration: none;
  cursor: pointer;
  text-align: center;
  padding-top: 16px;
  padding-bottom: 16px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  position: absolute;
  bottom: 20px;
}

.respond-button-sticky {
  position: fixed;
}

/* .respond-button:hover {
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24), 0 17px 50px 0 rgba(0, 0, 0, 0.19);
} */

.respond-button-sticky {
  position: fixed;
}

.rsvp-title, .video-header {
  margin-left: auto;
  margin-right: auto;
  margin-top: 25px;
  margin-bottom: 25px;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: #ffffff;
  text-align: center;
}

.flip-card-front,
.flip-card-back {
  position: absolute;
}

.front-card-inner {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
}

.rsvp-guest-list {
  overflow: auto;
  flex-grow: 1;
}

.rsvp-title {
  width: 100%;
  text-align: center;
}

.attending-modal-true {
  width: 100% !important;
  position: fixed;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%)
}


.video-flip-button {
  position: absolute;
  z-index: 1;
  right: 20px;
  top: 20px;
  width: 30px;
  transform: translateZ(0);
}

.attending-modal-true {
  max-width: 600px;
}

.front-card-inner {
  border-radius: 15px;
  box-shadow: 0 10px 30px 0 rgba(112, 136, 210, 0.1);
  background-color: #2a3164;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  flex-flow: column;
}
.group-container .front-card-inner{
  background-color: white;
  color: #2a3164;
}
.group-container .rsvp-header--title{
  color:  #2a3164;
}
.group-container .rsvp-user-cell{
  color: #2a3164;
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -50px;
  margin-top: -50px;
  z-index: 5;
}

.rsvp-user-list {
  margin-bottom: 100px;
}


/* The flip card container - set the width and height to whatever you want. We have added the border property to demonstrate that the flip itself goes out of the box on hover (remove perspective if you don't want the 3D effect */
.flip-card {
  background-color: transparent;
  perspective: 1000px;
  /* Remove this if you don't want the 3D effect */
}

/* This container is needed to position the front and back side */
.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

/* Do an horizontal flip when you move the mouse over the flip box container */
.flip-card-flip .flip-card-inner {
  transform: rotateY(180deg);
}

/* Position the front and back side */
.flip-card-front,
.flip-card-back {
  position: absolute;
  -webkit-backface-visibility: hidden;
  /* Safari */
  backface-visibility: hidden;
}

/* Style the front side (fallback if image is missing) */
.flip-card-front {
  opacity: 0.99;
  /* fix for safari */
  z-index: 1;
  margin-bottom: 60px;
}

/* Style the back side */
.flip-card-back {
  color: white;
  transform: rotateY(180deg);
}

#overlay {
  position: fixed;
  /* Sit on top of the page content */
  width: 100%;
  /* Full width (cover the whole page) */
  height: 100%;
  /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.75;
  background-color: #15152d;
  cursor: pointer;
  /* Add a pointer on hover */
}
.rh5v-DefaultPlayer_component{
  background-color: transparent !important;
}
@media only screen and (max-width: 768px) {

  :root {
    --width: 100vw;
    --height: calc(var(--vh, 1vh) * 100);
    --inside-width: calc(var(--width) - 50px);
    --inside-height: calc(var(--inside-width) * 1.77);
    --video-height: calc(var(--inside-width) * 1.77);
  }

  .rsvp-container,
  .flip-card-front,
  .front-card-inner,
  .flip-card-back {
    width: var(--inside-width) !important;
    height: var(--inside-height);
  }
  .video-container {
    width: var(--inside-width) !important;
  }

  /* iphoneX length of screen */
  @media only screen and (max-height: 800px) {
    .video-container {
      height: var(--inside-height);
    }
  }

  .respond-button, .respond-video-button {
    width: var(--inside-width);
  }

  .video-element {
    width: var(--inside-width) !important;
    height: var(--video-height) !important;
  }
  .video-container{
    max-height: var(--video-height) !important;
  }
}

@media only screen and (min-width: 768px) {
  :root {
    --height: calc(var(--vh, 1vh) * 100);
    --width: calc(var(--height) * 0.5625);
    --inside-width: calc(var(--width) - 15%);
    --inside-height: calc(var(--inside-width) * 1.77);
    --video-height: calc(var(--inside-width) * 1.77);
  }

  /* show device background */
  .app-main {
    /* background-image: url(../../../../images/rsvp/silver@3x.png);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat; */
    width: var(--width) !important;
    height: var(--height);
    left: 50%;
    position: absolute;
    -webkit-transform: translateX(-50%);
    overflow: hidden;
    transform: translateX(-50%);
  }
  .rsvp-container,
  .flip-card-front,
  .front-card-inner,
  .flip-card-back {
    width: var(--inside-width) !important;
    height: var(--inside-height);
  }

  .main-container,
  .login-page {
    overflow: hidden;
    /* background: url(../../../../images/background-web.svg) no-repeat center center fixed; */
    background-color: var(--invites-bg-color);
    height: var(--inside-height) !important;
  }


  .main-container,
  .login-page,
  .attending-modal-true,
  .yes-modal-true,
  .rsvp-container {
    width: var(--inside-width) !important;
    border-radius: 20px;
    margin-left: auto;
    margin-right: auto;
  }

  .main-container,
  .login-page {
    width: var(--inside-width) !important;
    /* margin-top: 5% !important; */
    border-radius: 20px;
    margin-left: auto;
    margin-right: auto;
  }

  .yes-modal-true {
    margin-top: 0 !important;
    /* top: 80px; */
    height: var(--height) !important;
    /* bottom: 80px !important; */
  }

  #overlay {
    height: var(--height);
    width: 100vw;
    /* bottom: 80px !important; */
  }

  .attending-modal-true {
    width: var(--inside-width) !important;
    /* margin-bottom: 80px !important; */
  }

  .rsvp-container {
    position: relative;
    width: var(--inside-width) !important;
    padding-left: 20px;
    padding-right: 20px;
  }

  .respond-button {
    /* bottom: 70px; */
    width: var(--inside-width);
  }

  .flip-card-back,
  .video-container,
  .video-element {
    width: var(--inside-width) !important;
    height: var(--video-height) !important;
  }

  .flip-card-front,
  .flip-card-inner,
  .front-card-inner {
    width: var(--inside-width) !important;
  }
  .main-container{
    height: 100% !important;
  }

}
.button-accepted-response {
  background-image: linear-gradient(109deg, #19d4ae 4%, #22d99b 91%);
}
.button-maybe-response {
  background-image: linear-gradient(109deg, #ffa56c 4%, #f8995d 91%);
}
.button-declined-response{
  background-color: #2a3164;
}
.button-no-response{
  background-color: #2a3164;
}
/* .respond-button-details {
  width: 120px;
} */