.add-button {
    width: 62px;
    height: 62px;
    border-radius: 31px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-image: linear-gradient(314deg, #645aff 104%, #a573ff -10%);
    float: right;
}
.add-plus{
    width: 32px;
    height: 32px;
    background-repeat:no-repeat;
    background-size:contain;
    background-image: url(../../../src/images/plus-icon-23551.png);
}

/* .add-button:before {
    content: '\FF0B';
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    font-weight: bolder;
    color: white;
} */
.add-container{
    position: absolute;
    bottom: 15px;
    right: 20px;
    z-index: 100;
}

.add-menu-container {
    text-align: right;
}

.add-button-true {
    transform: rotate(-315deg);
    background-image: unset;
    background-color: white;
}
.add-button-true .add-plus {
    background-image: url('../../../src/images/plus-icon-23551-purple.png');
}

.add-button-item {
    background-image: linear-gradient(251deg, #8b63e6 82%, #7265e3 18%);
    border-radius: 6px;
    color: white;
    text-align: center;
    font-size: 15px;
    line-height: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 30px;
    padding-right: 30px;
    margin-bottom: 20px;
    border-radius: 24px;
}