

.rsvp-header--cost-bubble {
  grid-template-rows: 1fr 1fr;
}

.event-list{
  padding:20px;
  overflow-y: auto;
  height: calc(100vh - 100px);
}
.paybubble-cost {
  font-size: 0.917rem;
}
.paybubble-cost,
.paybubble-dollar {
  display: block;
}
.notes{
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: 0.16px;
  margin-top: 12px;
  margin-bottom: 12px;
}
.rsvp-header--calender-container{
  margin-right: 12px;
}
.rsvp-list-response-img{
  width:32px;
}
.solid{
  opacity: 0.27;
  border: solid 1px #9ea3d6;
  margin-top: 12px;
  margin-bottom: 12px;
}
.get-directions, .react-add-to-calendar__button, .react-add-to-calendar__dropdown > a{
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  color: #9ea3d6;
  text-decoration: none;
  margin-left: 1.1em;
}
.react-add-to-calendar__button{
  padding: unset;
  background-color: unset;
  color: #9ea3d6;
  border: unset;
}
.react-add-to-calendar__dropdown{
background-color: #2a3164;
font-size: 12px;
border-radius: 6px;
z-index:1;
}
.react-add-to-calendar__dropdown ul {
  padding-left: 0px;
}
.react-add-to-calendar__dropdown ul li {
  padding-top: 5px;
  padding-bottom: 5px;
}
.react-add-to-calendar__dropdown ul li a {
  padding-top: 5px;
  color:white;
  text-decoration: none;
}
.paybubble {
  border-radius: 50%;
  align-content: center;
  color: white;
}
.rsvp-list--mesagge-bubble > img {
  width: 2rem;
}
.rsvp-header--title-many {
  font-size: 1rem;
  padding: 0 0 0 0.25rem;
}
.rsvp-list--mesagge-bubble {
  border-radius: 50%;
}
.rsvp-header--group-invite-cost {
  grid-template-columns: 3.688rem 9.813rem 3.688rem;
  align-self: center;
  justify-items: center;
}
.rsvp-header--group-invite {
  flex-direction: column;

}
.rsvp-header--group-invite-confirm-by {
  background-color: var(--invites-link-color);
  border-radius: 1.5rem 1.5rem 0 0;
  min-height: 3.333rem;
}
.rsvp-header--group-invite-confirm-by-text {
  font-size: 1.33rem;
}
.rsvp-header--group-invite-cost-bubble > img {
  position: relative;
  top: 0.25rem;
  width: 1.417rem;
  height: 1.417rem;
}
.rsvp_header--group-number {
  padding: 0 0 0 5px;
}
.rsvp-header--group-invite-group-title {
  padding: 1rem;
}
.paybubble,
.rsvp-header--group-invite-group-title {
  text-align: center;
}
.paybubble-cost,
.rsvp_header--group-number {
  font-family: var(--invites-main-font);
  font-weight: var(--invites-regular-font);
}
.paybubble-dollar,
.rsvp-header--title,
.rsvp-header--group-invite-confirm-by,
.rsvp-header--group-invite-group-title,
.rsvp-header--group-invite-cost-register,
.rsvp-header--group-invite-cost-amount {
  font-family: var(--invites-main-font);
  font-weight: var(--invites-medium-font);
}
.rsvp-header--group-invite-cost-register,
.rsvp-header--group-invite-cost-bubble,
.rsvp-header--group-invite-cost-amount {
  background-color: var(--invites-dark-bg);
  border-radius: 0.5rem;
  line-height: 40px;
  padding: 0 0.54rem;
}
.rsvp-list--mesagge-bubble,
.rsvp-header--group-invite,
.rsvp-header--group-invite-confirm-by {
  display: flex;
}
.calendar {
  background-image: url(../images/calenderBck/calenderBck@3x.png);
  display: grid;
  grid-template-rows: 1fr 1fr;
  width: 40px;
  height: 40px;
  background-size: cover;
  justify-items: center;
}
.calendar-month {
  padding-top: 2px;
  font-family: var(--invites-main-font);
  font-weight: var(--invites-medium-font);
  font-size: 9px;
}
.calendar-day {
  position: relative;
  color: black;
  font-family: var(--invites-main-font);
  font-weight: var(--invites-medium-font);
  font-size: 17px;
  top: -3px;
}
.calendar > img {
  width: 40px;
  height: 40px;
}
.rsvp-list--mesagge-bubble,
.rsvp-header--group-invite-confirm-by,
.rsvp-header--group-invite-cost {
  align-items: center;
}
.paybubble-dollar,
.rsvp-header--group-invite-group-title,
.rsvp-header--group-invite-cost-amount {
  font-size: 1.417rem;
}
.rsvp-header--group-invite-cost-register,
.rsvp_header--group-number {
  font-size: 1rem;
}
.paybubble,
.rsvp-header--cost-bubble,
.rsvp-header--cost-date,
.rsvp-header--group-invite-cost {
  display: flex;
  justify-content: space-between;
}
.rsvp-header--title{
  color: white;
  flex-grow:1;
  margin-left: 16px;
  margin-top: 2px;
}
.rsvp-header--cost-date,
.rsvp-header--group-invite-cost {
  justify-items: center;
}
.paybubble-cost,

.rsvp-header--date-location {
  display: flex;
  align-content: space-between;
  text-align: left;
}
.sub-header-text{
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}

.rsvp-list--mesagge-bubble,
.rsvp-header--group-invite-confirm-by {
  justify-content: center;
}
.rsvp-list--mesagge-bubble,
.paybubble {
  background-color: var(--invites-bg-color);
  width: 4.917rem;
  height: 4.917rem;
}
.confirmed-count{
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  color: #9ea3d6;
}
.event-list .rsvp-header--group-invite{
  margin-bottom: 16px;
}
.event-list .rsvp-header--event-invite{
  margin-bottom: 16px;
}
.event-list .rsvp-header--cost-date{
  border-radius: 7px;
  padding: 24px;
}
.event-list .solid{
  display: none;
}
.rsvp-group-item{
  background-color: white;
}
.rsvp-event-item{
  background-color: #2a3164;
}
.rsvp-group-item > .rsvp-header--title, .rsvp-group-item >  .notes{
  color: #2a3164;
}
