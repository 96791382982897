.pay-modal-true-true {
  animation: scrollInPay 0.25s forwards;
}
.pay-modal--button {
  outline: none;
}
.pay-modal--button:hover {
  cursor: pointer;
}
.pay-modal-true-true,
.pay-modal-false-true {
  position: fixed;
  background-color: white;
  color: black;
  display: grid;
  border-radius: 5% 5% 0px 0px;
  grid-template-rows: 4.167rem 4.167rem 4.167rem 10rem;
  grid-gap: 1rem;
  justify-items: center;
  bottom: 0;
  min-width: 100vw;
  margin-left: -1rem;
  height: 400px;
}
.pay-modal-false-false {
  display: none;
}
.pay-modal-false-true {
  animation: scrollOutPay 1s forwards;
}
.pay-modal--header {
  text-align: center;
  font-size: 1.75rem;
  font-family: var(--invites-main-font);
  font-weight: var(--invites-medium-font);
}
.pay-modal--venmo-link {
  display: inherit;
  width: 100%;
  text-decoration: none;
}
.pay-modal--button {
  width: 88%;
  margin: 0.5rem auto;
  height: 4.167rem;
  border-radius: 0.833rem;
  border: none;
  background: var(--invites-pay-gradient);
  color: white;
}
.pay-modal--venmo {
  font-size: 1.25rem;
  font-family: var(--invites-main-font);
  font-weight: var(--invites-medium-font);
}
.pay-modal--paid {
  font-size: 1.417rem;
  font-family: var(--invites-main-font);
  font-weight: var(--invites-medium-font);
}
.pay-modal--venmo-icon {
  width: 6.75rem;
  padding: 0;
  margin: 0 0 -0.25rem 0.5rem;
}
.pay-modal--cancel {
  color: var(--invites-link-color);
  font-size: 1.333rem;
  margin: auto auto 1rem auto;
}
@media screen and (min-width: 700px) {
  .pay-modal-true-true,
  .pay-modal-false-true {
    min-width: 0;
    margin: 0;
    width: 50%;
  }
  .pay-modal-true-true {
    animation: scrollInPayLarge 0.25s forwards;
  }
  .pay-modal-false-true {
    animation: scrollOutPayLarge 0.25s forwards;
  }
}

@media screen and (min-width: 1050px) {
  .pay-modal-true-true,
  .pay-modal-false-true {
    min-width: 0;
    margin: 0;
    width: 600px;
  }
}
@keyframes scrollInPay {
  from {
    right: -400px;
  }
  to {
    right: 00px;
  }
}
@keyframes scrollOutPay {
  from {
    right: 0px;
  }
  to {
    right: 4000px;
  }
}
@keyframes scrollInPayLarge {
  from {
    bottom: -400px;
  }
  to {
    bottom: 0;
  }
}
@keyframes scrollOutPayLarge {
  from {
    left: 0;
  }
  to {
    left: -2500px;
  }
}
