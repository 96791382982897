

.picker-modal-mask {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 1;

  display: none;
  width: 100%;
  height: 100%;

  background: rgba(0, 0, 0, 0.6);
}

.picker-modal {
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 2;

  width: 100%;

  background-color: #efeff4;

  transform: translate(0, 100%);
  backface-visibility: hidden;
  transition: transform .3s;

  &.picker-modal-toggle {
    transform: translate(0, 0);
  }
}