

:root {
  --invites-bg-color: #3c3c67;
  --invites-list-bg-color: rgb(48 53 99);
  --invites-cal-gradient: linear-gradient(to right,
      rgb(165 115 255),
      rgb(100 90 255));
  --invites-pay-gradient: linear-gradient(to right,
      rgb(139 99 230),
      rgb(114 101 227));
  --invites-secondary-font-color: rgb(156 158 185);
  --invites-main-font: Rubik;
  --invites-medium-font: 500;
  --invites-regular-font: 400;
  --invites-bold-font: 700;
  --invites-nunito-font: Nunito;
  --invites-button-bg-color: #f4f6fa;
  --invites-link-color: rgb(114 101 227);
  --invites-dark-bg: rgb(37 43 82);
  --invites-form-holder: rgb(158 163 214);
  --invites-light-periwinkle: rgb(214 217 224);
  --invites-response-modal-text: rgb(54 56 88);
  --invites-secondary-link-color: rgb(154 111 255);
}

html {

  background: url(../images/background-web.svg) no-repeat center center fixed;
  background-color: var(--invites-bg-color);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

body {
  margin: 0;
  font-family: 'Rubik', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.vertical-center-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.intl-tel-input {
  width: 240px;
  margin-left: auto;
  margin-right: auto;
}

.intl-tel-input>input {
  padding-left: 50px !important;
}

/* ::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background: white;
  border-radius: 10px;
} */
.rsvp-guest-list,
.event-list {
  overflow-y: scroll;
  scrollbar-width: none;
  /* Firefox */
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
}

.rsvp-guest-list::-webkit-scrollbar,
.event-list::-webkit-scrollbar {
  /* WebKit */
  width: 0;
  background: transparent;
  /* Optional: just make scrollbar invisible */
}

.fake-padding {
  height: 90px;
}

.rsvp-image {
  background:
    url(../images/icon-copy-2.svg);
  width: 44px;
  height: 44px;
  left: 0;
  right: 0;
  top: 22px;
  margin-left: auto;
  margin-right: auto;
  opacity: 1;
  z-index: 200;
  position: absolute
}

.image-container {
  position: absolute;
  width: 100%;
  height: 120px;
  background:
    url(../images/confetti.svg);
  opacity: 1;
  z-index: 100;
  top: 0;
  background-color: rgb(48 53 99);
  -webkit-mask-image: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
  mask-image: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));

}
:focus {outline:none;}
.image-container-bottom {
  position: absolute;
  width: 100%;
  height: 120px;
  opacity: 1;
  z-index: 10;
  bottom: 0;
  background-color: rgb(48 53 99);
  -webkit-mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
  mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));

}


.event-list {
  -webkit-mask-image: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
  mask-image: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
}