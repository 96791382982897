/* @import 'antd/es/select/style/css'; */


/* @import '~antd/dist/antd.css'; */

#visible-false {
  display: none;
}
.create-event--group-banner {
  padding: 11px 22px;
}
.ant-modal-body {
  max-width: 100vw;
}
.create-event--group-banner > h1 {
  height: 30px;
  font-family: Rubik;
  font-size: 30px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #ffffff;
}
.react-datepicker__input-container > input,
#visible-true > textarea,
input {
  font-family: Rubik;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.88;
  letter-spacing: 0.23px;
  color: #ffffff;
}
textarea{
  box-sizing: border-box;
  padding: 1rem;
}
input {
  text-align: center;

}
.create-event--group-banner > h3 {
  height: 38px;
  font-family: Rubik;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  color: #9ea3d6;
}
textarea#visible-true,
input#visible-true {
  width: 331px;
}


.react-datepicker__input-container > input,
#visible-true > textarea,
#visible-true > input,
form > .css-2b097c-container > .css-1pahdxg-control,
form > .css-2b097c-container > .css-yk16xz-control {
  width: 331px;
  height: 50px;
  border-radius: 10px;
  box-shadow: 0 10px 20px 0 rgba(64, 117, 205, 0.08);
  background-color: #20254d;
  border: none;
  color: #ffffff;
  margin: 22px;
}

.css-1uccc91-singleValue {
  color: white !important;
}

.css-b8ldur-Input {
  margin: 30px;
}

.create-form > .respond-button {
  bottom: auto;
  margin: 0;
  border: none;
  width: 331px;
}

.create-invite--options{
  display: grid;
  grid-template-rows: 1fr 1fr;
  padding: 30px;
}
.confirm-members,
.schedule-event{
  display: grid;
  grid-template-columns: 3fr 1fr;
  align-items: center;
  justify-items: center;
}

.confirm-members > div > h2,
.schedule-event > div > h2{
  font-family: Rubik;
  font-size: 17px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #ffffff;
}

.confirm-members > div > h4,
.schedule-event > div > h4 {
  font-family: Rubik;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  color: #9c9eb9;
}

.schedule-event > hr {
  width: 130%;
  border-top: .75px solid #9ea3d6;
}

.mobile-select {
  display: grid;
  /* grid-template-columns: 3fr 1fr; */
  width: 331px;
  height: 50px;
  border-radius: 10px;
  box-shadow: 0 10px 20px 0 rgba(64, 117, 205, 0.08);
  background-color: #20254d;
  margin: 22px auto 16px;
  align-items: center;
  justify-items: center;
}

.select-label {
  font-family: Rubik;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.88;
  letter-spacing: normal;
  color: #ffffff;
}

.attending-modal-false {
  display: none;
}
.attending-modal-true {
  overflow: scroll;
}
.group-list {
  width: 331px;
  min-height: 50px;
  border-radius: 23.5px;
  background-color: #f4f6fa;
  margin: 10px auto;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Rubik;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  color: #2a3164;
}

.create-form > .respond-button {
  /* z-index: -1; */
}

.false {
  display: none;
}

.create-form > .attending-modal-true {
  display: flex;
  flex-direction: column;
  z-index: 1;
}

.true > .rsvp-user-list{
  margin: 22px;
}

.true > .rsvp-user-list > div > .rsvp-user-cell{
  font-family: Rubik;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.88;
  letter-spacing: 0.23px;
  color: #ffffff;
  padding-top: .45rem;
  padding-bottom: .45rem;
}

.create-event--group-banner{
  background-color: #303563;
  padding-top: 20px;
  padding-bottom: 30px;
}

/* .select-label > svg {
  position: relative;
  left: 60%;
} */

.mobile-select > .select-label,
.mobile-select > svg {
  position: relative;
}

.mobile-select > .select-label{
  top: 25%;
}

.mobile-select > svg {
  left: 43%;
    bottom: 83%;
}

.inputing-group{
  display: grid;
  grid-template-rows: 1fr 1fr;
  justify-content: center;
}

.attending-modal-true > h2 {
  font-family: Rubik;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0.29px;
  text-align: center;
  color: #393939;
}

.event-name-form > form > div{
  display: flex;
    flex-direction: column;
    align-items: center;
}

.true > div > .react-datepicker__input-container{
  display: flex;
  justify-content: space-around;
}

.true .rsvp-user-list {
  margin-top: 0;

}

.respond-button.final-response {
  border: none;
}

.details-select-list{
  margin: 20px;
  font-family: Rubik;
  font-size: 17px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 3.94;
  letter-spacing: normal;
  color: #ffffff;
}

.details-select {
  border-bottom: .5px solid #9ea3d6;
}

.details-select > .rsvp-details-cell{
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
}

.details-select > .rsvp-details-cell > svg {
  justify-self: end;
}

.rc-time-picker.xxx{
  display: none;
}

.pop-up{
  z-index: 1;
  padding: 38px 14px 30px;
}

.money-plus-minus {
  border-radius: 10px;
  background-color: #f4f6fa;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0 10px;  
}

.venmo-entry > input {
  background-color: #f4f6fa;
  border: none;
  width: 33%;
  font-size: 28px;;
  color: black;
}

.money-plus-minus > div {
  width: 100%;
  text-align: center;
}

.money-plus-minus > svg {
  padding: 15px;
}
.pay-required {
  font-family: Rubik;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0.29px;
  text-align: center;
  color: #393939;
}

.venmo-entry {
  border-radius: 10px;
  background-color: #f4f6fa;
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.venmo-entry > input{
  font-size: 14px;
}
.respond-button--pay{
  border-radius: 23.5px;
  background-image: linear-gradient(95deg, #8b63e6 3%, #7265e3);
  font-size: 15px;
  font-weight: 500;
  color: #ffffff;
  cursor: pointer;
  text-align: center;
  height: 44px;
  width: 100%;
  display: flex;
    align-items: center;
    justify-content: center;
}

.respond-button--clear{
  font-family: Rubik;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #7265e3;
}

.member-plus-minus > .money-plus-minus{
  margin: 0
}
.member-plus-minus{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -38px;
}
.member-plus-minus > div > label{
  font-family: Rubik;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  color: #9c9eb9;
  position: relative;
  top: 20px;
}
.custom-label{
  text-align: center;
}

.attending-modal-true.pop-up.member-limits{
  padding: 0 14px;
}
.member-limits > .pay-required {
  margin-top: 38px;
} 

.map-or-link{
  width: 100%;
    display: grid;
    grid-template-columns: auto auto;
    justify-content: space-around;
    background-color: #f4f6fa;
    border-radius: 10px;
    height: 35px;
    align-content: center;
}

.true.venmo-entry.link-add > input{
  width: 100%;
  text-align: left;
  padding-left: 1rem;
}

.venmo-entry.location-search {
  height: 40px;
  margin-top: 1rem;
}

#visible-true > textarea {
  line-height: 24px;
}