.menu-line {
    width: 23px;
    height: 2px;
    background-color: white;
    margin: 5px 0;
  }
  .menu-line-small {
    width: 18px;
    height: 2px;
    background-color: white;
    margin: 5px 0;
    margin-right: 4px;
  }
  .menu-button{
      width: 26px;
      height: 26px;
      position: absolute;
      bottom: 30px;
      left: 30px;
  }