.login-page,
.login-page--form-submit,
.login-page--form-input {
  text-align: center;
}

.login-page,
.login-page--form-submit {
  font-family: var(--invites-main-font);
}

.login-page--form-submit:hover {
  cursor: pointer;
}

.login-page--form-input {
  font-size: 1.438rem;
}

.login-page--greeting>h1 {
  font-size: 1.063rem;
  color: var(--invites-light-periwinkle);
  font-family: var(--invites-main-font);
  font-weight: var(--invites-regular-font);
}

.login-page--form-input,
.login-page--form-submit {
  display: block;
}

.login-page--form-input,
.login-page--form-submit {
  border: none;
}

.login-page {
  margin: auto;
  margin: 20% auto;
  font-weight: var(--invites-regular-font);
}

.login-page--greeting {
  padding: 20px;
  margin: 0 10%;
  color: var(--invites-light-periwinkle);
}

.login-page--form {
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-gap: 40px;
  justify-content: center;
}

.login-page--form-input {
  color: var(--invites-form-holder);
  background-color: transparent;
}

.login-page--form-submit {
  border-radius: 23.5px;
  text-align: center;
  background-image: linear-gradient(95deg, #8b63e6 3%, #7265e3);
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-decoration: none;
  cursor: pointer;
  text-align: center;
  padding-top: 16px;
  padding-bottom: 16px;
  color: white;
}

.login-page--form-submit,
.login-page--form-input {
  outline: none;
}

.login-page--resend-code {
  font-family: var(--invites-main-font);
  font-weight: var(--invites-regular-font);
  color: var(--invites-form-holder);
  font-size: 0.938rem;
}

.login-page--resend-code-button {
  font-family: var(--invites-main-font);
  font-weight: var(--invites-medium-font);
  color: var(--invites-secondary-link-color);
  font-size: 0.938rem;
  text-decoration: underline;
  background-color: transparent;
  border: none;
  /* outline: none; */
}

.intl-tel-input .country-list {
  background-color: var(--invites-bg-color);
  color: white;
  border-width: 0;
}

.intl-tel-input {
  padding-left: 0;
}

.intl-tel-input .country-list {
  max-height: 400px;
}

.login-page--form-input {
  height: 100%;
  width: 100%;
}

.error-text{
  color: red;
  font-weight: bold;
  font-size: large;
}
.flag-container {
  color: white;
}